var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"centered":"","width":"500px","mask-closable":false,"title":"审核","ok-button-props":{ props: { loading: _vm.submitting } }},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"sfm-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"审核结果"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status',{
              initialValue: 'passed'
            }]),expression:"['status',{\n              initialValue: 'passed'\n            }]"}]},[_c('a-radio',{attrs:{"value":"passed"}},[_vm._v(" 通过 ")]),_c('a-radio',{attrs:{"value":"not_pass"}},[_vm._v(" 未通过 ")])],1)],1),_c('a-form-item',{attrs:{"label":"平台反馈"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['reason', {
              initialValue: '尊敬的用户，经调查发现您举报的内容属实，软猫已对举报对象进行处罚，感谢您的支持与监督。',
              rules: [
                { required: true, message: '请输入平台反馈' },
              ]
            }]),expression:"['reason', {\n              initialValue: '尊敬的用户，经调查发现您举报的内容属实，软猫已对举报对象进行处罚，感谢您的支持与监督。',\n              rules: [\n                { required: true, message: '请输入平台反馈' },\n              ]\n            }]"}],attrs:{"autocomplete":"off","auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }