<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      width="500px"
      :mask-closable="false"
      title="审核"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <a-form
        class="sfm-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="审核结果">
            <a-radio-group
              v-decorator="['status',{
                initialValue: 'passed'
              }]"
            >
              <a-radio value="passed">
                通过
              </a-radio>
              <a-radio value="not_pass">
                未通过
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="平台反馈">
            <a-textarea
              v-decorator="['reason', {
                initialValue: '尊敬的用户，经调查发现您举报的内容属实，软猫已对举报对象进行处罚，感谢您的支持与监督。',
                rules: [
                  { required: true, message: '请输入平台反馈' },
                ]
              }]"
              autocomplete="off"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { ReportRecordModel } from '@/models/report_record.js'
const reportRecordModel = new ReportRecordModel()

export default {
  name: 'ApprovalReportModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'approval_record' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          reportRecordModel.update(values, this.id).then((res) => {
            if (res.code === 0) {
              this.$notification.open({
                message: '提示',
                description: res.message,
                duration: 2
              })
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 5px;
}

.avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
  float: left;
}

.icon {
  width: 86px;
}
</style>
